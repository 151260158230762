<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <!-- head en -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Head Name (En)" vid="head_name_en" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="head_name_en">
                <template v-slot:label>
                  {{ $t('teaGarden.head_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="head_name_en"
                  v-model="data.head_name_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- head bn -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Head Name (Bn)" vid="head_name_bn" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="head_name_bn">
                <template v-slot:label>
                  {{ $t('teaGarden.head_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="head_name_bn"
                  v-model="data.head_name_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- amount  -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Amount" vid="amount" :rules="{required: false}">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="amount">
                <template v-slot:label>
                  {{ $t('teaGarden.amount') }}
                </template>
                <b-form-input
                  id="amount"
                  v-model="data.amount"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- employee types  -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Employee Type" vid="empoyee_types" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="empoyee_types">
                <template v-slot:label>
                  {{ $t('teaGarden.employee_type') }} <span class="text-danger">*</span>
                </template>
                <b-form-checkbox-group
                    :state="errors[0] ? false : (valid ? true : null)"
                    class="custom-control-inline-wrapper" size="lg" v-model="data.employee_types" :options="employee_types" name="">
                </b-form-checkbox-group>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- head_type  -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Add Or Deduct" vid="add_deduct" rules="required">
                <b-form-group
                  slot-scope="{ valid, errors }"
                  label-for="amount">
                  <template v-slot:label>
                    {{ $t('teaGarden.add_deduct') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-radio-group
                      :state="errors[0] ? false : (valid ? true : null)"
                      class="custom-control-inline-wrapper" size="lg" v-model="data.add_deduct" :options="headAccountType" name="">
                  </b-form-radio-group>
                  <div class="invalid-feedback d-block">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Sorting Order" vid="sorting_order" :rules="{required: true}">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="sorting_order">
                <template v-slot:label>
                  {{ $t('teaGarden.sorting_order') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="sorting_order"
                  v-model="data.sorting_order"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { wagesHeadStore, wagesHeadUpdate } from '../../../api/routes'
import { helpers } from '@/utils/helper-functions'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.gdnTaskList()
      this.data = tmp
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    employee_types () {
      return this.$store.state.TeaGardenService.commonObj.employeeType
    },
    headAccountType () {
      return this.$store.state.TeaGardenService.commonObj.headAccountType
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${wagesHeadUpdate}/${this.id}`, this.data)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, wagesHeadStore, this.data)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    gdnTaskList () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
